import { Link } from "react-scroll";
import "./App.scss";
import Logo from "./assets/logo.png";
import SectionImg from "./assets/p2.png";
import Hello from "./assets/hello.png";
import M from "./assets/m2.png";
import G from "./assets/G3.png";
import S from "./assets/S1.png";
import B from "./assets/b.png";
import R from "./assets/R2.png";
import MO from "./assets/mo.png";
import MS from "./assets/ms2.png";
import AS from "./assets/AS2.png";
import Milk from "./assets/milk.png";
import Salad from "./assets/salad.png";
import Grills from "./assets/المشويات.png";
import Western from "./assets/الغربي.png";
import Shawarma from "./assets/الشاورما.png";
import Broasted from "./assets/البروستد.png";
import Burger from "./assets/البرجر.png";
import Rosto from "./assets/الروستو.png";
import Appetizers from "./assets/المقبلات.png";
import HotDrinks from "./assets/مشروبات.png";
import Juices from "./assets/العصاير.png";
import Milkshake from "./assets/ميلك تشيك.png";
import Seasonalsalad from "./assets/سلطة.png";
import HotSoup from "./assets/السخن.png";
import Soup from "./assets/soup.png";
import facebook from "./assets/facebook.png";
import twitter from "./assets/twitter.png";
import instagram from "./assets/instagram.png";

import { useRef, useState } from "react";
import { useEffect } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import ReactFlagsSelect from "react-flags-select";

function App() {
  const [sliderNumber, setSliderNumber] = useState(0);
  const [selected, setSelected] = useState("SA");
  const [hasScrolledPast100vh, setHasScrolledPast100vh] = useState(false);

  const sliderRef = useRef();
  const handleClick = (direction) => {
    let distance = sliderRef.current.getBoundingClientRect().x;
    if (direction === "right" && sliderNumber < 11) {
      setSliderNumber(sliderNumber + 1);

      sliderRef.current.style.transform = `translateX(${124 + distance}px)`;
    }
    if (direction === "left" && sliderNumber > 0) {
      setSliderNumber(sliderNumber - 1);
      sliderRef.current.style.transform = `translateX(${-124 + distance}px)`;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset || window.scrollY;
      const viewportHeight = window.innerHeight;
      const scrolledPast100vh = scrollPosition > viewportHeight;

      setHasScrolledPast100vh(scrolledPast100vh);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="App">
      <div className="lang">
        <ReactFlagsSelect
          countries={["SA", "US", "RU"]}
          customLabels={{
            SA: "AR",
            US: "EN",
            RU: "RU",
          }}
          onSelect={(code) => setSelected(code)}
          placeholder="Select Language"
          selected={selected}
          className="language"
        />
        <div className="social">
          <a
            href="https://www.facebook.com/AmerAldimashqi"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="Facebook" />
          </a>

          {/* <img src={twitter} alt="" /> */}
          <a
            href="https://www.instagram.com/ameraldimashqi_hurghada"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="Facebook" />
          </a>
        </div>
      </div>

      <header className="App-header">
        <img src={Logo} alt="" className="logo" />
        <div className="welcome-container">
          <img src={Hello} alt="" className="hello" />
        </div>
      </header>
      {selected === "SA" && (
        <>
          <div className={hasScrolledPast100vh ? "slider scroll" : "slider"}>
            <div className="wrapper">
              <IoIosArrowForward
                className="slider-arrow right"
                onClick={() => handleClick("right")}
              />
              <div className="container" ref={sliderRef}>
                <Link
                  className="slider-link"
                  to="mshweat"
                  smooth={true}
                  duration={500}
                >
                  المشويات
                </Link>
                <Link
                  className="slider-link"
                  to="gharbi"
                  smooth={true}
                  duration={500}
                >
                  الغربي
                </Link>
                <Link
                  className="slider-link"
                  to="shawrma"
                  smooth={true}
                  duration={500}
                >
                  الشاورما
                </Link>
                <Link
                  className="slider-link"
                  to="brosted"
                  smooth={true}
                  duration={500}
                >
                  البروستد
                </Link>
                <Link
                  className="slider-link"
                  to="burger"
                  smooth={true}
                  duration={500}
                >
                  البرجر
                </Link>
                <Link
                  className="slider-link"
                  to="rosto"
                  smooth={true}
                  duration={500}
                >
                  الروستو
                </Link>
                <Link
                  className="slider-link"
                  to="soup"
                  smooth={true}
                  duration={500}
                >
                  السخن
                </Link>
                <Link
                  className="slider-link"
                  to="moqblat"
                  smooth={true}
                  duration={500}
                >
                  المقبلات
                </Link>
                <Link
                  className="slider-link"
                  to="hot"
                  smooth={true}
                  duration={500}
                >
                  المشروبات الساخنة
                </Link>
                <Link
                  className="slider-link"
                  to="juices"
                  smooth={true}
                  duration={500}
                >
                  العصائر
                </Link>
                {/* <Link
                  className="slider-link"
                  to="milk"
                  smooth={true}
                  duration={500}
                >
                  الميلك شيك
                </Link> */}
                {/* <Link
                  className="slider-link"
                  to="salad"
                  smooth={true}
                  duration={500}
                >
                  السلطة الموسمية
                </Link> */}
              </div>

              <IoIosArrowBack
                className="slider-arrow left"
                onClick={() => handleClick("left")}
              />
            </div>
          </div>

          {/*المشويات*/}
          <section className="section mshweat">
            <div className="container">
              <div className="item">
                <img src={Grills} className="title-img" alt="" />
                <img src={M} alt="" className="section-img" />
                <span>تقدم مع ( تومية + كاتشب + + مخلل + خبز )</span>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة كفتة</p>
                  <p className="price">175 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">نصف كيلو كفتة</p>
                  <p className="price">310 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">كيلو كفتة</p>
                  <p className="price">600 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة كباب</p>
                  <p className="price">275 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">نصف كيلو كباب</p>
                  <p className="price">450 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">كيلو كباب</p>
                  <p className="price">900 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة ميكس جريل</p>
                  <p className="price">275 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">نصف كيلو ميكس جريل</p>
                  <p className="price">450 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">كيلو ميكس جريل</p>
                  <p className="price">850 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة ريش</p>
                  <p className="price">350 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">نصف كيلو ريش</p>
                  <p className="price">525 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">كيلو ريش</p>
                  <p className="price">1000 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">نصف كيلو شيش طاوق</p>
                  <p className="price">300 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">كيلو شيش طاوق</p>
                  <p className="price">600 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ربع فرخة ورك</p>
                  <p className="price">90 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ربع فرخة صدر</p>
                  <p className="price">110 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">نصف فرخة</p>
                  <p className="price">160 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">فرخة كاملة</p>
                  <p className="price">300 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">فرخة كاملة</p>
                  <p className="price">300 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة كفتة فراخ</p>
                  <p className="price">100 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">نص كيلو كفتة فراخ</p>
                  <p className="price">225 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">كيلو كفتة فراخ</p>
                  <p className="price">400 LE</p>
                </div>
              </div>

              <div className="item">
                <span>يضاف 12% خدمة قيمة الخدمة داخل الصالة</span>
              </div>
            </div>
          </section>

          {/*الغربي*/}

          <section className="section gharbi">
            <div className="container">
              <div className="item">
                <img src={Western} className="title-img" alt="" />
                <img src={G} alt="" className="section-img" />
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ساندوتش بطاطس</p>
                  <p className="price">35 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ساندوتش كرسبي</p>
                  <p className="price">70 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ساندوتش كرسبي شيدر</p>
                  <p className="price">85 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ساندوتش فاهيتا فراخ</p>
                  <p className="price">70 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ساندوتش اسكالوب</p>
                  <p className="price">70 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة فاهيتا فراخ</p>
                  <p className="price">180 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة اسكالوب</p>
                  <p className="price">175 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ساندوتش شيش طاووق</p>
                  <p className="price">70 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ساندوتش كفتة</p>
                  <p className="price">70 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ساندوتش تشيكن بربكيو</p>
                  <p className="price">70 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ساندوتش فرنشيسكو</p>
                  <p className="price">80 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة تشيكن بربكيو</p>
                  <p className="price">180 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة فاهيتا فراخ</p>
                  <p className="price">180 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة اسكالوب </p>
                  <p className="price">175 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة فرنشيسكو</p>
                  <p className="price">200 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة شيش طاووق </p>
                  <p className="price">200 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة فراخ مكسيكى </p>
                  <p className="price">180 LE</p>
                </div>
              </div>

              <div className="item">
                <span>يضاف 12% خدمة قيمة الخدمة داخل الصالة</span>
              </div>
            </div>
          </section>

          {/*الشاورما*/}

          <section className="section shawrma">
            <div className="container">
              <div className="item">
                <img src={Shawarma} className="title-img" alt="" />
                <img src={S} alt="" className="section-img" />
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ساندوتش شاورما فراخ وسط</p>
                  <p className="price">70 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ساندوتش شاورما فراخ كبير</p>
                  <p className="price">80 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">شاورما ماريا فراخ</p>
                  <p className="price">140 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة شاورما فراخ</p>
                  <p className="price">130 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">نصف كيلو شاورما فراخ</p>
                  <p className="price">275 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">كيلو شاورما فراخ</p>
                  <p className="price">530 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ساندوتش شاورما لحمه وسط</p>
                  <p className="price">80 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ساندوتش شاورما لحمه كبير</p>
                  <p className="price">90 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">شاورما ماريا لحمه</p>
                  <p className="price">150 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة شاورما لحمه</p>
                  <p className="price">170 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">نصف كيلو شاورما لحمه</p>
                  <p className="price">370 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">كيلو شاورما لحمه</p>
                  <p className="price">700 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">فتة شاورما فراخ</p>
                  <p className="price">100 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">فتة شاورما لحمه</p>
                  <p className="price">130 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">فتة شاورما ميكس</p>
                  <p className="price">130 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">فتة شاورما ميكس</p>
                  <p className="price">150 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة عربي فراخ</p>
                  <p className="price">100 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة عربي فراخ دبل</p>
                  <p className="price">175 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة عربي لحمه</p>
                  <p className="price">110 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة عربي لحمه دبل</p>
                  <p className="price">185 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة عربي ميكس</p>
                  <p className="price">110 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة عربي ميكس دبل</p>
                  <p className="price">185 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">شاورما فراخ كيزر</p>
                  <p className="price">40 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">شاورما لحمة كيزر</p>
                  <p className="price">40 LE</p>
                </div>
              </div>

              <div className="item">
                <span>يضاف 12% خدمة قيمة الخدمة داخل الصالة</span>
              </div>
            </div>
          </section>

          {/*البروستد*/}

          <section className="section brosted">
            <div className="container">
              <div className="item">
                <img src={Broasted} className="title-img" alt="" />
                <img src={B} alt="" className="section-img" />
                <span>تقدم مع ( تومية + كاتشب + بطاطس + مخلل + خبز )</span>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title"> وجبة بروست 2 قطعة صدر + جناح</p>
                  <p className="price">110 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة بروست 2 قطعة ورك + دبوس</p>
                  <p className="price">100 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة بروست 3 قطعة</p>
                  <p className="price">150 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة بروست 4 قطعة</p>
                  <p className="price">190 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة بروست 8 قطعة</p>
                  <p className="price">340 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة بروست 16 قطعة</p>
                  <p className="price">650 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة كرسبي 3 قطع</p>
                  <p className="price">140 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة كرسبي 5 قطع</p>
                  <p className="price">200 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة زنجر 3 قطع</p>
                  <p className="price">140 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة زنجر 5 قطع</p>
                  <p className="price">200 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة كرسبى شيدر 3 قطع</p>
                  <p className="price">165 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة زنجر شيدر 3 قطع</p>
                  <p className="price">165 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة 8 ناجتس اطفال</p>
                  <p className="price">90 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة بانيه 3 قطع </p>
                  <p className="price">140 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">وجبة بانيه 5 قطع </p>
                  <p className="price">200 LE</p>
                </div>
              </div>

              <div className="item">
                <span>يضاف 12% خدمة قيمة الخدمة داخل الصالة</span>
              </div>
            </div>
          </section>

          {/*البرجر*/}

          <section className="section burger">
            <div className="container">
              <div className="item">
                <img src={Burger} className="title-img" alt="" />
                <img src={SectionImg} alt="" className="section-img" />
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">برجر كلاسيك بيف</p>
                  <p className="price">130 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">برجر بيف تشيز</p>
                  <p className="price">150 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">دبل برجر بيف</p>
                  <p className="price">220 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">برجر فراخ</p>
                  <p className="price">120 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">برجر فراخ تشيز</p>
                  <p className="price">140 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">برجر الدمشقي</p>
                  <p className="price">250 LE</p>
                </div>
              </div>
              <div className="item">
                <span>يضاف 12% خدمة قيمة الخدمة داخل الصالة</span>
              </div>
            </div>
          </section>

          {/*الروستو*/}

          <section className="section rosto">
            <div className="container">
              <div className="item">
                <img src={Rosto} className="title-img" alt="" />
                <img src={R} alt="" className="section-img" />
                <span>تقدم مع ( تومية + كاتشب + أرز + مخلل + خبز )</span>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">نصف فرخة روستو</p>
                  <p className="price">170 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">فرخة روستو</p>
                  <p className="price">320 LE</p>
                </div>
              </div>
              <div className="item">
                <span>يضاف 12% خدمة قيمة الخدمة داخل الصالة</span>
              </div>
            </div>
          </section>

          {/*السخن*/}

          <section className="section soup">
            <div className="container">
              <div className="item">
                <img src={HotSoup} className="title-img" alt="" />
                <img src={Soup} alt="" className="section-img" />
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">بطاطس محمرة </p>
                  <p className="price">30 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">كبدة مقلية </p>
                  <p className="price">30 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">برك لحمة </p>
                  <p className="price">20 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">برك جبنة </p>
                  <p className="price">20 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">شوربة لسان عصفور</p>
                  <p className="price">30 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">شوربة عدس</p>
                  <p className="price">35 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ارز بالشعريه</p>
                  <p className="price">25 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">ارز بسمتي</p>
                  <p className="price">35 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ملوخيه</p>
                  <p className="price">30 LE</p>
                </div>
              </div>

              <div className="item">
                <span>يضاف 12% خدمة قيمة الخدمة داخل الصالة</span>
              </div>
            </div>
          </section>

          {/*المقبلات*/}

          <section className="section moqblat">
            <div className="container">
              <div className="item">
                <img src={Appetizers} className="title-img" alt="" />
                <img src={MO} alt="" className="section-img" />
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">بابا غنوج</p>
                  <p className="price">35 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">متبل</p>
                  <p className="price">35 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">خيار بالزبادي</p>
                  <p className="price">25 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">فتوش </p>
                  <p className="price">25 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">تبولة </p>
                  <p className="price">50 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">سلطة بلدي</p>
                  <p className="price">25 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">مسحبة</p>
                  <p className="price">65 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">طحينة</p>
                  <p className="price">30 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">تومية</p>
                  <p className="price">25 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">خيار مخلل</p>
                  <p className="price">15 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">محمرة</p>
                  <p className="price">25 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">يالنيجي ورق عنب</p>
                  <p className="price">65 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">كول سلو</p>
                  <p className="price">35 LE</p>
                </div>
              </div>

              <div className="item">
                <span>يضاف 12% خدمة قيمة الخدمة داخل الصالة</span>
              </div>
            </div>
          </section>

          {/*المشروبات الساخنة*/}

          <section className="section hot">
            <div className="container">
              <div className="item">
                <img src={HotDrinks} className="title-img" alt="" />
                <img src={MS} alt="" className="section-img" />
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">شاي</p>
                  <p className="price">20 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">شاي بحليب</p>
                  <p className="price">25 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">شاي بدوي 2 فرد</p>
                  <p className="price">50 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">شاي اخضر</p>
                  <p className="price">25 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">زنجبيل</p>
                  <p className="price">35 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">قهوة تركى سنجل</p>
                  <p className="price">30 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">قهوة تركي دبل</p>
                  <p className="price">50 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">اسبريسو سنجل</p>
                  <p className="price">35 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">اسبرسو دبل</p>
                  <p className="price">60 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">قهوة فرنساوي</p>
                  <p className="price">35 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">نسكافيه حليب</p>
                  <p className="price">40 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">نسكافية بلاك</p>
                  <p className="price">35 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">كابتشينو</p>
                  <p className="price">40 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ميكاتو</p>
                  <p className="price">50 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">نعناع</p>
                  <p className="price">20 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">كركدية سخن</p>
                  <p className="price">25 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">يانسون</p>
                  <p className="price">20 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">امريكانو كوفي</p>
                  <p className="price">35 LE</p>
                </div>
              </div>

              <div className="item">
                <span>يضاف 12% خدمة قيمة الخدمة داخل الصالة</span>
              </div>
            </div>
          </section>

          {/*العصائر*/}

          <section className="section juices">
            <div className="container">
              <div className="item">
                <img src={Juices} className="title-img" alt="" />
                <img src={AS} alt="" className="section-img" />
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">برتقال</p>
                  <p className="price">65 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">مانجو</p>
                  <p className="price">80 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">لبمون</p>
                  <p className="price">50 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ليمون نعناع</p>
                  <p className="price">60 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">جوافة</p>
                  <p className="price">70 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">عيران</p>
                  <p className="price">40 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">ليمون برتقال</p>
                  <p className="price">65 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">فراولة</p>
                  <p className="price">80 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">بطيخ</p>
                  <p className="price">70 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">موز بحليب</p>
                  <p className="price">80 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">موز بحليب فراولة</p>
                  <p className="price">90 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">فراولة برتقال</p>
                  <p className="price">90 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">فينامين سى </p>
                  <p className="price">85 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title"> مشروبات غاوية بأنواعه </p>
                  <p className="price">25 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title"> شويبس </p>
                  <p className="price">30 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title"> مياه صغيره </p>
                  <p className="price">10 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title"> مياة فوارة </p>
                  <p className="price">30 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title"> ريدبول </p>
                  <p className="price">60 LE</p>
                </div>
              </div>

              <div className="item">
                <span>يضاف 12% خدمة قيمة الخدمة داخل الصالة</span>
              </div>
            </div>
          </section>
        </>
      )}

      {/* English Menu */}

      {selected === "US" && (
        <>
          <div className={hasScrolledPast100vh ? "slider scroll" : "slider"}>
            <div className="wrapper">
              <IoIosArrowForward
                className="slider-arrow right"
                onClick={() => handleClick("right")}
              />
              <div className="container" ref={sliderRef}>
                <Link
                  className="slider-link"
                  to="mshweat"
                  smooth={true}
                  duration={500}
                >
                  Grills
                </Link>
                <Link
                  className="slider-link"
                  to="gharbi"
                  smooth={true}
                  duration={500}
                >
                  Western Dishes
                </Link>
                <Link
                  className="slider-link"
                  to="shawrma"
                  smooth={true}
                  duration={500}
                >
                  Shawarma
                </Link>
                <Link
                  className="slider-link"
                  to="brosted"
                  smooth={true}
                  duration={500}
                >
                  Brochettes
                </Link>
                <Link
                  className="slider-link"
                  to="burger"
                  smooth={true}
                  duration={500}
                >
                  Burgers
                </Link>
                <Link
                  className="slider-link"
                  to="rosto"
                  smooth={true}
                  duration={500}
                >
                  Roasts
                </Link>
                <Link
                  className="slider-link"
                  to="soup"
                  smooth={true}
                  duration={500}
                >
                  Hot Dishes
                </Link>
                <Link
                  className="slider-link"
                  to="moqblat"
                  smooth={true}
                  duration={500}
                >
                  Appetizers
                </Link>
                <Link
                  className="slider-link"
                  to="hot"
                  smooth={true}
                  duration={500}
                >
                  Hot Drinks
                </Link>
                <Link
                  className="slider-link"
                  to="juices"
                  smooth={true}
                  duration={500}
                >
                  Juices
                </Link>
                <Link
                  className="slider-link"
                  to="milk"
                  smooth={true}
                  duration={500}
                >
                  Milkshakes
                </Link>
                <Link
                  className="slider-link"
                  to="salad"
                  smooth={true}
                  duration={500}
                >
                  Fruit Salad
                </Link>
              </div>

              <IoIosArrowBack
                className="slider-arrow left"
                onClick={() => handleClick("left")}
              />
            </div>
          </div>

          {/*المشويات*/}
          <section className="section mshweat">
            <div className="container">
              <div className="item">
                <img src={Grills} className="title-img" alt="" />
                <img src={M} alt="" className="section-img" />
                <span>Served with (tomia + ketchup + pickles + bread)</span>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Kofta meal</p>
                  <p className="price">175 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Half kilo kofta</p>
                  <p className="price">310 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Kilo kofta</p>
                  <p className="price">600 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Kebab meal</p>
                  <p className="price">275 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Half kilo kebab</p>
                  <p className="price">450 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Kilo kebab</p>
                  <p className="price">900 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Mix grill meal</p>
                  <p className="price">275 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Half kilo Mix grill</p>
                  <p className="price">450 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Kilo Mix grill</p>
                  <p className="price">850 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Grilled ribs meal</p>
                  <p className="price">350 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Half kilo grilled ribs</p>
                  <p className="price">525 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Kilo grilled ribs</p>
                  <p className="price">1000 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Half kilo shish tawook</p>
                  <p className="price">300 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Kilo shish tawook</p>
                  <p className="price">600 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Quarter chicken thigh</p>
                  <p className="price">90 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Quarter chicken breast</p>
                  <p className="price">110 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Half chicken</p>
                  <p className="price">160 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Whole chicken</p>
                  <p className="price">300 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Chicken kofta meal</p>
                  <p className="price">100 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Half kilo chicken kofta</p>
                  <p className="price">225 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Kilo chicken kofta</p>
                  <p className="price">400 LE</p>
                </div>
              </div>
              <div className="item">
                <span>12% service charge added for dining in</span>
              </div>
            </div>
          </section>

          {/*الغربي*/}
          <section className="section gharbi">
            <div className="container">
              <div className="item">
                <img src={Western} className="title-img" alt="" />
                <img src={G} alt="" className="section-img" />
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Potato sandwich</p>
                  <p className="price">35 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Crispy sandwich</p>
                  <p className="price">70 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Crispy cheddar sandwich</p>
                  <p className="price">85 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Chicken fajita sandwich</p>
                  <p className="price">70 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Escalope sandwich</p>
                  <p className="price">70 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Chicken fajita meal</p>
                  <p className="price">180 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Escalope meal</p>
                  <p className="price">175 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Shish tawook sandwich</p>
                  <p className="price">70 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Kofta sandwich</p>
                  <p className="price">70 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Chicken barbecue sandwich</p>
                  <p className="price">70 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Franchesco sandwich</p>
                  <p className="price">80 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Chicken barbecue meal</p>
                  <p className="price">180 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Franchesco meal</p>
                  <p className="price">200 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Shish tawook meal</p>
                  <p className="price">200 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Mexican chicken meal</p>
                  <p className="price">180 LE</p>
                </div>
              </div>

              <div className="item">
                <span>12% service charge added for dining in</span>
              </div>
            </div>
          </section>

          {/*الشاورما*/}
          <section className="section shawrma">
            <div className="container">
              <div className="item">
                <img src={Shawarma} className="title-img" alt="" />
                <img src={S} alt="" className="section-img" />
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Small chicken shawarma sandwich</p>
                  <p className="price">70 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Large chicken shawarma sandwich</p>
                  <p className="price">80 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Chicken shawarma mariah</p>
                  <p className="price">140 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Chicken shawarma meal</p>
                  <p className="price">130 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Half kilo chicken shawarma</p>
                  <p className="price">275 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Kilo chicken shawarma</p>
                  <p className="price">530 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Small meat shawarma sandwich</p>
                  <p className="price">80 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Large meat shawarma sandwich</p>
                  <p className="price">90 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Meat shawarma mariah</p>
                  <p className="price">150 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Meat shawarma meal</p>
                  <p className="price">170 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Half kilo meat shawarma</p>
                  <p className="price">370 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Kilo meat shawarma</p>
                  <p className="price">700 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Chicken shawarma fatta</p>
                  <p className="price">100 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Meat shawarma fatta</p>
                  <p className="price">130 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Mixed shawarma fatta</p>
                  <p className="price">130 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Mixed shawarma mariah fatta</p>
                  <p className="price">150 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Chicken araby meal</p>
                  <p className="price">100 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Double chicken araby meal</p>
                  <p className="price">175 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Meat araby meal</p>
                  <p className="price">110 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Double meat araby meal</p>
                  <p className="price">185 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Mixed araby meal</p>
                  <p className="price">110 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Double mixed araby meal</p>
                  <p className="price">185 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Chicken shawarma caesar</p>
                  <p className="price">40 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Meat shawarma caesar</p>
                  <p className="price">40 LE</p>
                </div>
              </div>

              <div className="item">
                <span>12% service charge added for dining in</span>
              </div>
            </div>
          </section>

          {/*البروستد*/}

          <section className="section brosted">
            <div className="container">
              <div className="item">
                <img src={Broasted} className="title-img" alt="" />
                <img src={B} alt="" className="section-img" />
                <span>
                  Served with (tomia + ketchup + potatoes + pickles + bread)
                </span>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">
                    Broasted meal 2 pieces breast and wing
                  </p>
                  <p className="price">110 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">
                    Broasted meal 2 pieces thigh and drumstick
                  </p>
                  <p className="price">100 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Broasted meal 3 pieces</p>
                  <p className="price">150 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Broasted meal 4 pieces</p>
                  <p className="price">190 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Broasted meal 8 pieces</p>
                  <p className="price">340 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Broasted meal 16 pieces</p>
                  <p className="price">650 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Crispy meal 3 pieces</p>
                  <p className="price">140 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Crispy meal 5 pieces</p>
                  <p className="price">200 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Zinjer meal 3 pieces</p>
                  <p className="price">140 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Zinjer meal 5 pieces</p>
                  <p className="price">200 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Crispy cheddar meal 3 pieces</p>
                  <p className="price">165 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Zinjer cheddar meal 3 pieces</p>
                  <p className="price">165 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Kids meal 8 nuggets</p>
                  <p className="price">90 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Pane meal 3 pieces</p>
                  <p className="price">140 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Pane meal 5 pieces</p>
                  <p className="price">200 LE</p>
                </div>
              </div>
              <div className="item">
                <span>12% service charge added for dining in</span>
              </div>
            </div>
          </section>

          {/*البرجر*/}

          <section className="section burger">
            <div className="container">
              <div className="item">
                <img src={Burger} className="title-img" alt="" />
                <img src={SectionImg} alt="" className="section-img" />
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Classic beef burger</p>
                  <p className="price">130 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Beef cheese burger</p>
                  <p className="price">150 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Double beef burger</p>
                  <p className="price">220 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Chicken burger</p>
                  <p className="price">120 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Chicken cheese burger</p>
                  <p className="price">140 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Al Dameshqi burger</p>
                  <p className="price">250 LE</p>
                </div>
              </div>
              <div className="item">
                <span>12% service charge added for dining in</span>
              </div>
            </div>
          </section>

          {/*الروستو*/}

          <section className="section rosto">
            <div className="container">
              <div className="item">
                <img src={Rosto} className="title-img" alt="" />
                <img src={R} alt="" className="section-img" />
                <span>
                  Served with (tomia + ketchup + rice + pickles + bread)
                </span>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Half chicken rosto</p>
                  <p className="price">170 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Whole chicken rosto</p>
                  <p className="price">320 LE</p>
                </div>
              </div>
              <div className="item">
                <span>12% service charge added for dining in</span>
              </div>
            </div>
          </section>

          {/*السخن*/}
          <section className="section soup">
            <div className="container">
              <div className="item">
                <img src={HotSoup} className="title-img" alt="" />
                <img src={Soup} alt="" className="section-img" />
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Baked Potatoes</p>
                  <p className="price">30 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Fried Liver</p>
                  <p className="price">30 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Meat Pirik</p>
                  <p className="price">20 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Cheese Pirik</p>
                  <p className="price">20 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Bird's Tongue Soup</p>
                  <p className="price">30 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Lentil Soup</p>
                  <p className="price">35 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Rice with Vermicelli</p>
                  <p className="price">25 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Basmati Rice</p>
                  <p className="price">35 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Mulukhiyah</p>
                  <p className="price">30 LE</p>
                </div>
              </div>
              <div className="item">
                <span>12% service charge added for dining in</span>
              </div>
            </div>
          </section>

          {/*المقبلات*/}
          <section className="section moqblat">
            <div className="container">
              <div className="item">
                <img src={Appetizers} className="title-img" alt="" />
                <img src={MO} alt="" className="section-img" />
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Baba Ghanouj</p>
                  <p className="price">35 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Muttabbal</p>
                  <p className="price">35 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Cucumber Yogurt</p>
                  <p className="price">25 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Fattoush</p>
                  <p className="price">25 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Tabbouleh</p>
                  <p className="price">50 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Baladi Salad</p>
                  <p className="price">25 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Msabbaha</p>
                  <p className="price">65 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Tahini</p>
                  <p className="price">30 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Toum (Garlic Sauce)</p>
                  <p className="price">25 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Pickled Cucumber</p>
                  <p className="price">15 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Muhammara</p>
                  <p className="price">25 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Yalaneji (Stuffed Grape Leaves)</p>
                  <p className="price">65 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Coleslaw</p>
                  <p className="price">35 LE</p>
                </div>
              </div>
              <div className="item">
                <span>12% service charge added for dining in</span>
              </div>
            </div>
          </section>

          {/*المشروبات الساخنة*/}
          <section className="section hot">
            <div className="container">
              <div className="item">
                <img src={HotDrinks} className="title-img" alt="" />
                <img src={MS} alt="" className="section-img" />
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Tea</p>
                  <p className="price">20 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Tea with milk</p>
                  <p className="price">25 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Bedouin tea for 2</p>
                  <p className="price">50 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Green tea</p>
                  <p className="price">25 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Ginger</p>
                  <p className="price">35 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Single Turkish coffee</p>
                  <p className="price">30 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Double Turkish coffee</p>
                  <p className="price">50 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Single espresso</p>
                  <p className="price">35 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Double espresso</p>
                  <p className="price">60 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">French coffee</p>
                  <p className="price">35 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Nescafe with milk</p>
                  <p className="price">40 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Black Nescafe</p>
                  <p className="price">35 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Cappuccino</p>
                  <p className="price">40 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Macchiato</p>
                  <p className="price">50 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Mint</p>
                  <p className="price">20 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Hot karkadeh</p>
                  <p className="price">25 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Anise</p>
                  <p className="price">20 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Americano coffee</p>
                  <p className="price">35 LE</p>
                </div>
              </div>
              <div className="item">
                <span>12% service charge added for dining in</span>
              </div>
            </div>
          </section>

          {/*العصائر*/}
          <section className="section juices">
            <div className="container">
              <div className="item">
                <img src={Juices} className="title-img" alt="" />
                <img src={AS} alt="" className="section-img" />
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Orange</p>
                  <p className="price">65 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Mango</p>
                  <p className="price">80 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Lemon</p>
                  <p className="price">50 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Lemon mint</p>
                  <p className="price">60 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Guava</p>
                  <p className="price">70 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Aran (Buttermilk)</p>
                  <p className="price">40 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Lemon orange</p>
                  <p className="price">65 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Strawberry</p>
                  <p className="price">80 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Watermelon</p>
                  <p className="price">70 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Banana milkshake</p>
                  <p className="price">80 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Banana strawberry milkshake</p>
                  <p className="price">90 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Strawberry orange</p>
                  <p className="price">90 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Vitamin C</p>
                  <p className="price">85 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Soft drinks (various types)</p>
                  <p className="price">25 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Schweppes</p>
                  <p className="price">30 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Small water</p>
                  <p className="price">10 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Sparkling water</p>
                  <p className="price">30 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Red Bull</p>
                  <p className="price">60 LE</p>
                </div>
              </div>
              <div className="item">
                <span>12% service charge added for dining in</span>
              </div>
            </div>
          </section>

          {/*ميلك تيشك*/}

          {/* <section className="section milk">
            <div className="container">
              <div className="item">
                <img src={Milkshake} className="title-img" alt="" />
                <img src={Milk} alt="" className="section-img" />
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Nutella milkshake</p>
                  <p className="price">100 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Strawberry milkshake</p>
                  <p className="price">80 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Oreo milkshake</p>
                  <p className="price">90 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Mango milkshake</p>
                  <p className="price">80 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Snickers milkshake</p>
                  <p className="price">100 LE</p>
                </div>
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Bounty milkshake</p>
                  <p className="price">100 LE</p>
                </div>
              </div>
              <div className="item">
                <span>12% service charge added for dining in</span>
              </div>
            </div>
          </section> */}

          {/*سلطة موسمية*/}

          {/* <section className="section salad">
            <div className="container">
              <div className="item">
                <img src={Seasonalsalad} className="title-img" alt="" />
                <img src={Salad} alt="" className="section-img" />
              </div>
              <div className="item">
                <div className="wrapper">
                  <p className="title">Seasonal Salad</p>
                  <p className="price">100 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Nutella Salad</p>
                  <p className="price">110 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Emperor Special</p>
                  <p className="price">150 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Emperor Special Oreo</p>
                  <p className="price">150 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Emperor Special Nutella</p>
                  <p className="price">150 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Emperor Special Strawberry</p>
                  <p className="price">150 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Emperor Special Avocado</p>
                  <p className="price">150 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Fruit Salad with Cream and Honey</p>
                  <p className="price">140 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Arabic Ice Cream</p>
                  <p className="price">75 LE</p>
                </div>
              </div>

              <div className="item">
                <div className="wrapper">
                  <p className="title">Amer Al-Dimashqi Fruit Salad</p>
                  <p className="price">200 LE</p>
                </div>
              </div>
              <div className="item">
                <span>12% service charge added for dining in</span>
              </div>
            </div>
          </section> */}
        </>
      )}
    </div>
  );
}

export default App;
